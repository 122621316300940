import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { URLS } from "src/_config";
import { useEventApi } from "src/_common/hooks/actions/event/appEventApiHook";
import { useUserApi } from "src/_common/hooks/actions/user/appUserApiHook";
import { useAppUserAction } from "src/_common/hooks/actions/user/appUserActionHook";
import { useToaster } from "src/_common/hooks/actions/common/appToasterHook";
import { useMenuListSelector, useSettingsSelector, useGlobalTeamStatusSelector } from "src/_common/hooks/selectors/eventSelector";
import { useAppUserAuthSelector, useAppUserDetailsSelector } from "src/_common/hooks/selectors/userSelector";
import Ticker from "react-ticker";
import CardsModal from "../modals/CardsModal";
import TeamListModal from "../modals/TeamListModal";
import { useAppEventAction } from "src/_common/hooks/actions/event/appEventActionHook";
import { confirmAlert } from 'react-confirm-alert';
import TopHeaderScoreBar from "./TopHeaderScoreBar";
import SEOHook from "src/_common/hooks/custom/useSEO";
interface HeaderProps {
  gameId?: Number;
}

function Header({ gameId }: HeaderProps) {
  const toast = useToaster();
  //Call Redux Value
  const menuItems = useMenuListSelector();
  const settings = useSettingsSelector();
  const isAuth = useAppUserAuthSelector();
  const userDetails = useAppUserDetailsSelector();
  const teamStatus = useGlobalTeamStatusSelector();

  const { gameSlug } = useParams<any>();

  //Call API Hook
  const eventApi = useEventApi();
  const userApi = useUserApi();
  const eventAction = useAppEventAction();

  //Local State Variables
  const [leagueSearch, setLeagueSearch] = useState<any>(0);
  const [findMyTeam, setFindMyTeam] = useState<any>(0);
  const [leagueSearchBox, setLeagueSearchBox] = useState<any>(0);
  const [findMyTeamBox, setFindMyTeamBox] = useState<any>(false);
  const [showCardsModal, setShowCardsModal] = useState<boolean>(false);
  const [showTeamListModal, setShowTeamListModal] = useState<boolean>(false);
  const [leagueCode, setLeagueCode] = useState<string>("");
  const [mobMenu, setMobMenu] = useState<any>(false);
  //Save directly to Redux
  const userAction = useAppUserAction();
  const history = useHistory();
  const [statusMsg, setStatusMsg] = useState<any>([]);
  const [searchTeamValues, setSearchTeamValues] = useState<any>({
    searchZipCode: localStorage.getItem("searchZipCode") || "",
    searchEmail: localStorage.getItem("searchEmail") || "",
    searchTeamName: localStorage.getItem("searchTeamName") || "",
  });

  useEffect(() => {
    getMenuList();
    getSettingsList();
    getStatusMessage();
    getSEODetails();
  }, []);

  useEffect(() => {
    if (settings) {
      let league_search = Object.values(settings).findIndex((x: any) => x.key == "league_search");
      if (league_search > -1) {
        setLeagueSearch(settings[league_search].val);
      }
      let find_my_team = Object.values(settings).findIndex((x: any) => x.key == "find_my_team");
      if (find_my_team > -1) {
        setFindMyTeam(settings[find_my_team].val);
      }
    }

  }, [settings]);

  //Get SEO List
  const getSEODetails = () => {
    var params = {};

    eventApi.callSEO(
      params,
      (message: string, resp: any) => { },
      (message: string) => {
        toast.error(message);
      }
    );
  };

  //Get Menu List
  const getMenuList = () => {
    var params = {};

    eventApi.callGetMenuList(
      params,
      (message: string, resp: any) => { },
      (message: string) => {
        toast.error(message);
      }
    );
  };
  //Get Settings List
  const getSettingsList = () => {
    var params = {};

    eventApi.callSettings(
      params,
      (message: string, resp: any) => { },
      (message: string) => {
        toast.error(message);
      }
    );
  };

  //Get Status Message
  const getStatusMessage = () => {
    var params: any = {};
    if (gameSlug) {
      params["gameSlug"] = gameSlug;
    }

    eventApi.callGetStatusMessage(
      params,
      (message: string, resp: any) => {
        // console.log(resp?.status_list);
        setStatusMsg(resp.status_list);
      },
      (message: string) => {
        toast.error(message);
      }
    );
  };

  const logOut = (e: React.MouseEvent) => {
    userApi.callLogout(
      (message: string, resp: any) => {
        userAction.logout();
        history.push(URLS.LANDING);
      },
      (message: string) => {
        userAction.logout();
      }
    );
  };

  const openCardsModal = () => {
    setShowCardsModal(true);
  };

  const closeCardsModal = () => {
    setShowCardsModal(false);
  };

  const openTeamListModal = () => {
    if (leagueCode?.length) {
      setShowTeamListModal(true);
    } else {
      toast.error("Please enter league code to search");
    }
  };

  const closeTeamListModal = () => {
    setLeagueCode("");
    setShowTeamListModal(false);
  };

  const changeSearchTeamValues = (val: any, field: any) => {
    let x = { ...searchTeamValues };
    x[field] = val;
    setSearchTeamValues(x);
    localStorage.setItem(field, val);
  };

  const gotoSearchTeamPage = () => {
    let searchTeamName = localStorage.getItem("searchTeamName");
    let searchZipCode = localStorage.getItem("searchZipCode");
    let searchEmail: any = localStorage.getItem("searchEmail");
    if (!searchTeamName && !searchZipCode && !searchEmail) {
      toast.error("Please enter any of above information");
      return;
    } else if (searchEmail && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(searchEmail)) {
      toast.error("Please enter valid email address");
      return;
    } else if (teamStatus) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui'>
              <h1>Do you want to leave Create Team Page?</h1>
              <button className="dark-button" onClick={() => {
                onClose()
              }}>No</button>
              <button className="red-button" onClick={() => {
                if (window.location.pathname != URLS.SEARCHTEAM) {
                  history.push(URLS.SEARCHTEAM);
                } else {
                  eventAction.setIsSearchTeamPage(true);
                }
                eventAction.IsTeamCreateProcess(false)
                onClose()
              }}>Yes</button>
            </div>
          )
        }
      })
    } else {
      if (window.location.pathname != URLS.SEARCHTEAM) {
        history.push(URLS.SEARCHTEAM);
      } else {
        eventAction.setIsSearchTeamPage(true);
      }
    }
  };

  const openFindMyTeam = () => {
    setFindMyTeamBox(false);
    setLeagueSearchBox(false);
    if (findMyTeamBox) setFindMyTeamBox(false);
    else setFindMyTeamBox(true);
  };

  const openLeagueSearch = () => {
    setLeagueSearchBox(false);
    setFindMyTeamBox(false);
    if (leagueSearchBox) setLeagueSearchBox(false);
    else setLeagueSearchBox(true);
  };

  const goToPage = (url: any) => {
    if (teamStatus) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui'>
              <h1>Do you want to leave Create Team Page?</h1>
              <button className="dark-button" onClick={() => {
                onClose()
              }}>No</button>
              <button className="red-button" onClick={() => {
                history.push(url);
                eventAction.IsTeamCreateProcess(false)
                onClose()
              }}>Yes</button>
            </div>
          )
        }
      })
    } else {
      history.push(url);
    }
  };

  // useEffect(()=>{
  //   console.log({statusMsg})
  // },[statusMsg])

  useEffect(() => {
    if (
      window.location.pathname == URLS.SEARCHTEAM &&
      (searchTeamValues?.searchZipCode || searchTeamValues?.searchTeamName || searchTeamValues?.searchEmail)
    ) {
      setFindMyTeamBox(true);
    }
  }, [searchTeamValues]);

  return (
    <React.Fragment>
      <SEOHook />
      <div className="fixed-top-section">
        {statusMsg?.length ? (
          <div className="top-slider-text">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 text-center">
                  <div className="top-slider-text-inner">
                    <Ticker>
                      {() => (
                        <div>
                          {statusMsg.map((v: any, i: any) => {
                            return <span key={i}>{v.status_text + "        "}</span>;
                          })}
                        </div>
                      )}
                    </Ticker>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="logo-panel">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-12">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="top-ham-logo-panel d-flex gap-1">
                    <div className="mob-ham-menu">
                      <span className="linkcls" onClick={() => setMobMenu(true)}>
                        <img src="/images/ham-menu.svg" alt="" />
                      </span>
                    </div>

                    <div className="top-logo linkcls" onClick={(e) => goToPage(URLS.LANDING)}>
                      <img src="/images/top-logo.png" alt="" />
                    </div>
                  </div>
                  <div className="top-right-nav">
                    <ul>
                      {findMyTeam == 1 ? (
                        <li>
                          <a className="gap-2 linkcls" onClick={openFindMyTeam}>
                            <i className="search-icon"></i> Find Your Team
                          </a>
                        </li>
                      ) : null}
                      {leagueSearch == 1 ? (
                        <li>
                          <a className="gap-2 linkcls" onClick={openLeagueSearch}>
                            <i className="search-icon"></i> League{" "}
                          </a>
                        </li>
                      ) : null}
                      {menuItems &&
                        menuItems.header.map((v: any, l: number) => {
                          if (v.submenu.length > 0) {
                            return (
                              <li className="dropdown-menu sub-dropmenu" key={l}>
                                <a className="dropdown-toggle linkcls" data-bs-toggle="dropdown" aria-expanded="false">
                                  {v.menu}
                                </a>
                                {v.id == 2 ? (
                                  <ul className="dropdown-menu dropdown-menu-end">
                                    {menuItems && menuItems.games && menuItems.games.length > 0
                                      ? menuItems.games.map((v: any, i: any) => {
                                        return (
                                          <li key={i}>
                                            <a className="dropdown-item linkcls" onClick={(e) => goToPage(v.slug)}>
                                              {v.title} <span className="caret"></span>
                                            </a>
                                          </li>
                                        );
                                      })
                                      : null}
                                  </ul>
                                ) : (
                                  <ul className="dropdown-menu dropdown-menu-end">
                                    {v.submenu.map((p: any, j: number) => {
                                      return (
                                        <li key={j}>
                                          <a className="dropdown-item linkcls" onClick={(e) => goToPage(p.url)}>
                                            {p.menu} <span className="caret"></span>
                                          </a>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                )}
                              </li>
                            );
                          } else if (v.url_type == 1) {
                            return (
                              <li className="for-desktop" key={l}>
                                <a className="linkcls" onClick={(e) => goToPage(v.url)}>
                                  {v.menu}
                                </a>
                              </li>
                            );
                          } else {
                            return (
                              <li className="for-desktop" key={l}>
                                <a href={v.url} target="_blank">
                                  {v.menu}
                                </a>
                              </li>
                            );
                          }
                        })}
                      {isAuth ? (
                        <li className="account-dropdown">
                          <a href="#" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            <span className="mr-5 prof-icon">Profile</span>
                            <img src="/images/profile-icon.svg" alt="" />
                          </a>
                          <ul className="dropdown-menu dropdown-menu-end">
                            <li className="mb-2 logged-user-name">
                              <div className="logged-user d-flex gap-2 align-items-center">
                                <div className="logged-user-name-text">
                                  <h5>
                                    {" "}
                                    Welcome,{" "}
                                    <strong>
                                      {(userDetails && userDetails.first_name ? userDetails.first_name : "") +
                                        (userDetails && userDetails.last_name ? " " + userDetails.last_name : "") ||
                                        "User"}
                                    </strong>
                                  </h5>
                                </div>
                              </div>
                            </li>
                            <li>
                              <a className="dropdown-item linkcls" onClick={(e) => goToPage("/dashboard")}>
                                My Teams
                              </a>
                            </li>
                            <li>
                              <a onClick={(e) => openCardsModal()} className="dropdown-item linkcls">
                                My Cards
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item linkcls" onClick={(e) => goToPage("/my-account")}>
                                My Account
                              </a>
                            </li>
                            <li>
                              <a onClick={(e) => logOut(e)} className="dropdown-item">
                                Logout
                              </a>
                            </li>
                          </ul>
                        </li>
                      ) : (
                        <li className="login-link">
                          {" "}
                          <Link to={URLS.LOGIN}>Login</Link>
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className={mobMenu ? "mob-drawer-menu show" : "mob-drawer-menu"}>
                    <div className="mob-drawer-logo d-flex justify-content-between px-3 py-2">
                      <img src="/images/top-logo-s.png" alt="" />
                      <a onClick={() => setMobMenu(false)} className="drawer-close-btn linkcls">
                        <i className="drawer-close"></i>
                      </a>
                    </div>
                    <ul>
                      <li className="gap-2">
                        <a className="linkcls" onClick={(e) => goToPage(URLS.LANDING)}>Home</a>
                      </li>
                      {menuItems &&
                        menuItems.header.map((v: any, l: any) => {
                          if (v.submenu.length > 0) {
                            return (
                              <li className="sub-dropmenu" key={l}>
                                <a className="dropdown-toggle linkcls" data-bs-toggle="dropdown" aria-expanded="false">
                                  {v.menu}
                                </a>
                                {v.id == 2 ? (
                                  <ul className="dropdown-menu dropdown-menu-end">
                                    {menuItems && menuItems.games && menuItems.games.length > 0
                                      ? menuItems.games.map((v: any, i: any) => {
                                        return (
                                          <li key={i}>
                                            <a className="dropdown-item linkcls" onClick={(e) => goToPage(v.slug)}>
                                              {v.title} <span className="caret"></span>
                                            </a>
                                          </li>
                                        );
                                      })
                                      : null}
                                  </ul>
                                ) : (
                                  <ul className="dropdown-menu-end">
                                    {v.submenu.map((p: any, j: any) => {
                                      return (
                                        <li key={j}>
                                          <a className="dropdown-item linkcls" onClick={(e) => goToPage(p.url)}>
                                            {p.menu} <span className="caret"></span>
                                          </a>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                )}
                              </li>
                            );
                          } else if (v.url_type == 1) {
                            return (
                              <li key={l}>
                                <a className="linkcls" onClick={(e) => goToPage(v.url)}>
                                  {v.menu}
                                </a>
                              </li>
                            );
                          } else {
                            return (
                              <li key={l}>
                                <a href={v.url} target="_blank">
                                  {v.menu}
                                </a>
                              </li>
                            );
                          }
                        })}
                    </ul>
                  </div>
                  <div
                    className={mobMenu ? "mob-drawer-overlay show" : "mob-drawer-overlay"}
                    onClick={() => setMobMenu(false)}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={findMyTeamBox ? "collapse show" : "collapse"} id="findMyTeam">
          <div className="top-searh-fld-panel">
            <div className="container">
              <div className="top-searh-fld-panel-inner">
                <h6 className="mb-3">Note: Please just fill out 1 box to find your team</h6>
                <div className="d-flex align-items-center top-searh-fld-panel-flds">
                  <div className="flex-grow-1">
                    <input
                      type="text"
                      className="form-control"
                      maxLength={8}
                      placeholder="Zipcode"
                      value={searchTeamValues?.searchZipCode || localStorage.getItem("searchZipCode")}
                      onChange={(e) => changeSearchTeamValues(e.target.value, "searchZipCode")}
                    />
                  </div>
                  <div className="px-3">
                    <h5>OR</h5>
                  </div>
                  <div className="flex-grow-1">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Team name"
                      value={searchTeamValues?.searchTeamName || localStorage.getItem("searchTeamName")}
                      onChange={(e) => changeSearchTeamValues(e.target.value, "searchTeamName")}
                    />
                  </div>

                  <div className="px-3">
                    <h5>OR</h5>
                  </div>
                  <div className="flex-grow-1">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Owner email"
                      value={searchTeamValues?.searchEmail || localStorage.getItem("searchEmail")}
                      onChange={(e) => changeSearchTeamValues(e.target.value, "searchEmail")}
                    />
                  </div>
                </div>
                <button className="black-btn d-flex gap-2" onClick={gotoSearchTeamPage}>
                  <i className="search-icon"></i> SEARCH TEAM
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className={leagueSearchBox ? "collapse show" : "collapse"} id="LeagueSearch">
          <div className="top-searh-fld-panel">
            <div className="container">
              <div className="top-searh-fld-panel-inner">
                <h6 className="mb-3">Note: Please enter the League Code to find your League</h6>
                <div className="d-flex top-searh-fld-panel-flds gap-2">
                  <div className="league-code-fld">
                    <input
                      type="text"
                      className="form-control"
                      maxLength={9}
                      placeholder="League Code"
                      value={leagueCode}
                      onChange={(e) => setLeagueCode(e.target.value)}
                    />
                  </div>
                  <button className="black-btn d-flex gap-2 mt-0 py-2" onClick={openTeamListModal}>
                    <i className="search-icon"></i> SEARCH LEAGUE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <TopHeaderScoreBar />
      </div>

      {showCardsModal && <CardsModal shouldShow={showCardsModal} onClose={closeCardsModal} />}

      {showTeamListModal && leagueCode && (
        <TeamListModal shouldShow={showTeamListModal} onClose={closeTeamListModal} leagueCode={leagueCode} />
      )}
    </React.Fragment>
  );
}

export default Header;
